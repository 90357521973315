import React from "react";
import { Script } from "gatsby";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { GlobalProvider } from "./src/context/GlobalContext";

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LekFPImAAAAALgU08EiyZiqVwrN8Owa5wikSKH3"
      scriptProps={{
        async: true, // optional, default to false,
        defer: true, // optional, default to false
        appendTo: "body", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <GlobalProvider>{element}</GlobalProvider>
      <React.Fragment>
        <Script src="https://birdeye.com/embed/v6/187611/1/1206102014/c33d09a10a12078a0ad51da55b5cf67c53a1bd3a7d96b52b" />
        <div id="bf-revz-widget-1206102014"></div>
      </React.Fragment>
    </GoogleReCaptchaProvider>
  );
};
