import React, { useState, useCallback } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const [recaptchav3Token, setRecaptchav3Token] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const handleVerify = useCallback((token) => {
    setRecaptchav3Token(token);
    //setValue("_wpcf7_recaptcha_response", token);
  }, []);
  return (
    <GlobalContext.Provider
      value={{
        recaptchav3Token,
        setRecaptchav3Token,
        refreshReCaptcha,
        setRefreshReCaptcha,
      }}
    >
      {/* reCAPTCHA v3 */}
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshReCaptcha}
      />
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
